@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "../assets/styles/main";
@import "landing/index";

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/futura-book.woff2")format("woff2");
}
@font-face {
  font-family: "Gunterz";
  src: url("../assets/fonts/gunterz-med.woff2")format("woff2");
}
body {
  font-family:  sans-serif;
  background: $main_bg;
  color: $text_color;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}