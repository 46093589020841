.auth-wrapper {
  display: flex;

  min-height: 100dvh;

  .ant-card {
    background: #253d32;
    color: #fff;
    .ant-checkbox-wrapper {
      color: #fff;
    }
    .ant-card-head-title {
      color: #fff;
      font-size: 18px;
      font-family: $titleFont;
      padding-bottom: 0;
    }
  }

  &__content {
    margin:  auto;
    width: 400px;
    max-width: 95%;
  }

  .ant-form-item-label > label {
    font-size: 16px;
    line-height: 1;
    color: #fff;
  }
  .ant-input {
    border-radius: 0;
    background: #e2eee9 !important;
  }
  .ant-form-item-explain {
    padding: 0;
    font-size: 14px;
    margin-top: 5px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    border-radius: 0;
  }
  .ant-input-affix-wrapper {
    border-radius: 0;
    background: #e2eee9 !important;
  }

  a {
    color: #fff;
  }
}

.auth-form {
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px
  }
}