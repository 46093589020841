.h-100per {
  height: 100%;
}

.w-100per {
  width: 100%;
}

.padV10 {
  padding: 10px 0;
}

.padG10 {
  padding: 0 10px;
}

.mrV10 {
  margin: 10px 0;
}

.mrG10 {
  margin: 0 10px;
}

.fx-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fx-top-center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.fx-bottom-center {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.fxDirCol {
  flex-direction: column;
}

.fxDirRow {
  flex-direction: row;
}

.alItemsCenter {
  align-items: center;
}

.text-center {
  text-align: center;
}

.bgw {
  background: $alwaysWhite;
}

.bR10 {
  border-radius: 10px;
}