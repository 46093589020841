.landing-pages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100dvh;
  background: #fff;
  font-family: $fontPrimary, sans-serif;

  > * {
    width: 100%;
  }

}

.container {
  width: 100%;
  max-width: 1084px;
  margin: 0 auto;

  @include media('<1024px') {
    padding: 0 10px;
  }
}

.landing-header {
  box-shadow: 0px 4px 21.9px 22px rgba(0, 0, 0, 0.10);
  position: relative;
  z-index: 10;
  background: #fff;
}

.landing-header-content {
  display: flex;
  align-items: center;
  min-height: 100px;

  @include media('<1024px') {
    min-height: 65px;
  }

  &__main {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include media('<1024px') {
      display: none;
    }
  }

}

.burger-menu {
  width: 35px;
  //height: 35px;
  display: none;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  border: none;
  background: transparent;
  margin-left: auto;

  @include media('<1024px') {
    display: flex;
  }

  > * {
    height: 2px;
    width: 100%;
    background: $primaryColor;
  }
}

.header-nav {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 30px;
  margin-left: auto;
  margin-right: 30px;

  a {
    color: $text_color;
  }
}

.auth-buttons {
  display: flex;
  gap: 15px;
}

.footer {
  background: $primaryColor;

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 30px 0;

    @include media('<1024px') {
      flex-direction: column;
      padding: 20px 0;
    }
  }

  &__social {
    display: flex;
    list-style: none;
    gap: 20px;
    padding: 0;
    margin: 0;

    @include media('<1024px') {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      transition: all .2s ease;

      @include media('<1024px') {
        width: 40px;
        height: 40px;
      }

      &:hover {
        opacity: .5;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__menu {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 20px;
    list-style: none;
    margin-left: auto;

    @include media('<1024px') {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 30px auto;
      margin-bottom: 0;
    }

    a {
      color: #fff;
      font-size: 12px;

    }
  }
}

.commissions {
  background: $primaryColor;
}

.commissions-grid {
  display: flex;
  gap: 17px;
  padding: 30px 0;

  @include media('<1024px') {
    display: block;
  }
}

.commission-card {
  @include media('<1024px') {
    + * {
      margin-top: 20px;
    }
  }
  background: #fff;
  color: $primaryColor;
  border-radius: 20px;
  padding: 10px;
  height: 150px;
  display: flex;
  align-items: center;
  width: 100%;
  @include media('<1024px') {
    height: auto;
    padding: 15px 10px;
  }

  &__title {
    font-weight: 700;
    font-family: $titleFont;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 0;
  }

  &__icon {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex: none;
    pointer-events: none;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.get-started {
  text-align: center;
  padding-top: 30px;

  @include media('<1024px') {
    padding-top: 15px;
  }

  &--commissions {
    padding-top: 60px;

    @include media('<1024px') {
      padding-top: 30px;
    }
  }
}

.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 75px;

  @include media('<1024px') {
    padding-top: 50px;
  }

  > * {
    width: calc(100% / 3);
    padding: 0 20px;
    padding-bottom: 40px;

    @include media('<1024px') {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;

      + * {
        margin-top: 25px;
      }
    }
  }
}

.benefit-card {
  background: $primaryColor;
  border-radius: 20px;
  height: 175px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 25px;

  @include media('<1024px') {
    height: 130px;
  }

  &__title {
    font-size: 18px;
    text-transform: uppercase;
    font-family: $titleFont;
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
    color: #fff;

    @include media('<1024px') {
      font-size: 16px;
    }
  }

  &__icon {
    width: 120px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -35px;

    @include media('<1024px') {
      width: 90px;
      height: 60px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.custom-section {
  padding: 30px 0;
  @include media('<1024px') {
    padding: 25px 0;
  }

  &__title {
    text-align: center;
    font-family: $titleFont;
    text-transform: uppercase;
    color: $primaryColor;
    font-size: 42px;
    line-height: 1;

    @include media('<1024px') {
      font-size: 26px;
    }
  }
}

.our-benefits {
  padding-top: 60px;

  @include media('<1024px') {
    padding-top: 30px;
  }
}

.how-it-work-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 15px;

  > * {
    padding: 0 5px;
    width: calc(100% / 3);
  }
}

.how-it-works-card {
  &__number {
    background: #EEFFEE;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    font-size: 64px;
    font-weight: 900;
    font-family: $titleFont;
    color: $primaryColor;
    margin: 0 auto;
    text-shadow: -1px 0 1px #000, 1px 0 1px #000, 0px 1px 1px #000, 0px -1px 1px #000;
    @include media('<1024px') {
      width: 60px;
      height: 60px;
      font-size: 26px;
      text-shadow: none;
      margin-bottom: 10px;
    }
  }

  &__title {
    max-width: 210px;
    margin: 0 auto;
    margin-top: 25px;
    font-size: 18px;
    text-transform: uppercase;
    color: $primaryColor;
    font-family: $titleFont;
    text-align: center;

    @include media('<1024px') {
      font-size: 10px;
      margin-top: 10px;
      max-width: 150px;
    }
  }
}

.how-it-works {
  &__subtitle {
    font-size: 36px;
    margin-top: 10px;
    color: #51A159;
    text-align: center;
    font-weight: 900;

    @include media('<1024px') {
      font-size: 21px;
    }
  }
}

.contacts-section {
  padding-bottom: 60px;
}

.faq-img {
  width: 450px;
  max-width: 95%;
  margin: 0 auto;
  padding: 15px 0;

  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.faq-nav {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 15px;

  > * {
    + * {
      margin-left: 20px;
    }
  }

  @include media('<1024px') {
    display: block;

    > * {
      width: 100%;

      + * {
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }

  .btn {
    font-size: 16px;
    line-height: 35px;
    padding: 10px 20px;
    border: 2px solid $secondaryColor;
    background: $secondaryColor;

    @include media('<1024px') {
      font-size: 14px;
      line-height: 20px;
    }

    &--border {
      color: $secondaryColor;
      background: transparent;
    }
  }
}

.faq-item {
  background: $primaryColor;
  border-radius: 20px;
  margin-bottom: 30px;
  color: #fff;
  padding: 20px;
  @include media('<1024px') {
    border-radius: 15px;
    margin-bottom: 10px;
  }

  &__question {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: $titleFont;

    @include media('<1024px') {
      font-size: 14px;
    }
  }

  &__answer {
    opacity: .75;
    font-size: 16px;
    line-height: 1.5;

    @include media('<1024px') {
      font-size: 12px;
    }
  }
}

.terms-top {
  display: flex;
  position: relative;
  min-height: 500px;
  @include media('<1024px') {
    min-height: 220px;
  }

  &__title {
    font-size: 96px;
    max-width: 730px;
    word-spacing: 100vw;
    text-align: center;
    color: $primaryColor;
    font-family: $titleFont;
    line-height: 1.4;
    position: relative;
    z-index: 2;

    @include media('<1024px') {
      font-size: 32px;
      margin-left: -125px;
    }
  }

  &__img {
    position: absolute;
    right: -150px;
    width: 550px;

    @include media('<1024px') {
      width: 200px;
      right: -50px;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.terms {
  overflow: hidden;
}

.news-img {
  width: 450px;
  margin: 0 auto;
  max-width: 95%;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.news-grid-section {
  background: $secondaryColor;
  padding: 50px 0;
  margin: 30px 0;
  margin-bottom: 0;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @include media('<1024px') {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.news-preview {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  background: $primaryColor;
  color: #fff;
  text-align: center;
  padding-bottom: 15px;
  transition: all .2s ease;

  @include media('<1024px') {
    font-size: 12px;
  }

  &__img {
    height: 230px;
    width: 100%;

    @include media('<1024px') {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    text-align: center;
    font-family: $titleFont;
    text-transform: uppercase;
    padding: 0 15px;
    padding-top: 15px;

    @include media('<1024px') {
      font-size: 16px;
    }
  }

  &:hover {
    color: #fff;
    opacity: .85;
  }
}

.news-section {
  padding-bottom: 0;
}

.contact-form {
  background: #253d32;
  color: #fff;
  width: 550px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  z-index: 5;
  @include media('<1024px') {
    padding: 10px;
  }

  .custom-section__title {
    color: #fff;
  }

  &__text {
    font-size: 14px;
    line-height: 1.5;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include media('<1024px') {
      font-size: 14px;
    }
  }

  .ant-form-item-label > label {
    font-size: 16px;
    line-height: 1;
    color: #fff;
  }

  .ant-input {
    border-radius: 0;
    background: #e2eee9 !important;
  }

  .ant-form-item-explain {
    padding: 0;
    font-size: 12px;
    margin-top: 5px;
  }
}

.terms-content {
  color: $text_color !important;

  * {
    color: $text_color !important;
  }
}

.contact-img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -100px;
  margin-left: -300px;
  width: 700px;

  img {
    max-width: 100%;
    object-fit: contain;
  }

  @include media('<1024px') {
    display: none;
  }
}

.contacts {
  position: relative;
  overflow: hidden;
  padding-bottom: 200px;

  @include media('<1024px') {
    padding-bottom: 40px;
  }
}

.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }


  .header-nav {
    margin: auto;
    flex-direction: column;
    gap: 15px;
    text-align: center;

    li > * {
      font-size: 18px;
    }
  }

  .auth-buttons {
    padding: 15px;
    width: 100%;

    > * {
      width: 50%;
    }
  }
}

.close-menu {
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  background: none;
  right: 5px;
  top: 5px;
  position: absolute;
  display: block;

  > * {
    display: block;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -5%);
    height: 2px;
    background: $primaryColor;

    &:nth-child(1) {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.landing-logo {
  max-width: 180px;
  @include media('<1024px') {
    max-width: 140px;
  }

  img {
    max-width: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.news-content {
  background: $primaryColor;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid $primaryColor;

  &__content {
    padding: 20px;
  }

  &__img {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    @include media('<1024px') {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 18px;
    color: #fff;
  }

  &__text {
    opacity: .85;
    color: #fff;
  }
}

.main-banner {
  //background: #f9f9f9;
  padding: 30px 0;
  position: relative;
  min-height: 450px;
  overflow: hidden;
  @include media('<1024px') {
    min-height: initial;
  }
}

.main-banner-title {
  font-size: 36px;
  margin: 0;
  font-family: $titleFont;
  line-height: 1.5;
  text-align: center;
  color: $primaryColor;
  display: inline-block;
  padding-left: 245px;
  padding-top: 65px;
  position: relative;
  z-index: 2;
  @include media('<1024px') {
    padding: 0;
    display: block;
    font-size: 21px;
  }
}

.main-banner-image {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  pointer-events: none;

  &--1 {
    width: 450px;
    margin-left: -325px;
    margin-top: 0;
    @include media('<1024px') {
      width: 300px;
      margin-left: -130px;
    }
  }

  &--2 {
    width: 550px;
    margin-left: 400px;
    margin-top: -260px;
    @include media('<1024px') {
      width: 250px;
      margin-left: 220px;
      margin-top: -115px;
    }
  }

  img {
    width: 100%;
    object-fit: contain;
  }
}

.testimonial-img {
  max-width: 90%;
  width: 450px;
  margin: 0 auto;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.testimonial-card {
  background: #578973;
  color: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 450px;
  min-height: 200px;
  display: flex !important;
  flex-direction: column;
  transition: all .3s ease;
  position: relative;
  padding-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    right: 20px;
    bottom: -20px;
    width: 40px;
    height: 40px;
    background: url("../../assets/images/q-img.png") no-repeat center / contain;
  }
  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;

    @include media('<1024px') {
      font-size: 16px;
    }
  }
  &__text {
    font-size: 10px;
    line-height: 1.5;
    opacity: .75;
    font-style: italic;
  }

  &__link {
    color: #fff;
    font-size: 14px;
    margin-top: auto;
    line-height: 1.1;
    word-break: break-all;
  }
}

.testimonial-section {
  overflow: hidden;
}

.testimonial-slider {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 40px;

  @include media('<1024px') {
    padding-top: 15px;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-slide {
    pointer-events: none;
    position: relative;



    .testimonial-card {
      opacity: 0;
      transform: scale(.85);
      @include media('<1024px') {
        opacity: 1;
      }
    }
    &.slick-active {
      .testimonial-card {
        opacity: 1;
        pointer-events: all;
      }
    }
    &.slick-current {
      z-index: 10;

      .testimonial-card {
        transform: scale(1.3);
        opacity: 1;
        box-shadow: 0 0 20px -10px #000;
        background: $primaryColor;

        @include media('<1024px') {
          transform: scale(1.2);
        }

      }
    }
  }
}