.ant-table {
  tr.ant-table-expanded-row {
    td {
      background: #fafafa;
    }
    th {
      background: #eee;
    }
  }
}
